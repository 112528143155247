import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { Daphne, initDaphne } from "@syadem/daphne-js";
import { UserManager } from "oidc-client-ts";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppRouter, Config, CountryConfig } from "../src/appConfig";
import { Apis, initApis } from "../src/network/apis";
import { initQueries, Queries } from "../src/queries";
import { ResultNotifier } from "../src/resultNotifier";
import { initServices, Services } from "../src/services";
import { ServiceBus } from "../src/services/serviceBus";
import { AppStore, initStore } from "../src/store";
import { Loading } from "../src/ui/components/shared/Loading";
import { theme } from "../src/ui/layout/Theme";
import { LoadingAppFailed } from "../src/ui/pages/LoadingAppFailed";
import { DependenciesProvider } from "../src/ui/providers/Dependencies";
import { I18nProvider } from "../src/ui/providers/I18nProvider";
import { routes } from "../src/ui/routes";
import { SecurityNumberInput } from "./components/SecurityNumberInput";

export class App {
  private readonly router: AppRouter;
  private readonly store: AppStore;
  private readonly apis: Apis;
  private readonly services: Services;
  private readonly queries: Queries;
  private readonly serviceBus: ServiceBus;
  private readonly resultNotifier: ResultNotifier;
  private readonly countryConfig: CountryConfig;
  private daphne?: Daphne;

  constructor(private readonly config: Config) {
    const {
      oidcUrl,
      kairosProApiBasePath,
      kairosTeamApiBasePath,
      kairosCertApiBasePath,
      sadApiBasePath,
      arianeApiBasePath,
    } = config;

    const oidcManager = initOidcManager(oidcUrl);
    const currentToken = async () => (await oidcManager.getUser())?.access_token ?? "";

    this.store = initStore();
    this.resultNotifier = new ResultNotifier(this.store);

    this.apis = initApis({
      kairosProApiBasePath,
      kairosTeamApiBasePath,
      kairosCertApiBasePath,
      sadApiBasePath,
      arianeApiBasePath,
      accessToken: currentToken,
    });

    this.router = createBrowserRouter(routes(false));

    this.countryConfig = {
      defaultLocale: "lv",
      availableLocales: ["lv", "en-gb"],
      defaultCountryCode: "LVA",
      zipCode: {
        length: 7,
        regex: /^LV-\d{4}$/,
        placeholder: "ex : LV-1010",
        sharingPlaceholder: "ex : Riga, LV-1010, ...",
      },
      phoneNumber: {
        minLength: 8,
        maxLength: 15,
        regex: /^(?:\+371|00371)?\s?[2-9]\s?[0-9]{7}$/,
        placeholder: "ex : +371 25071543",
      },
      securityNumber: {
        validator: (_value: string | undefined) => {
          return true;
        },
        Input: SecurityNumberInput,
      },
    };

    this.queries = initQueries({
      store: this.store,
      apis: this.apis,
    });

    this.services = initServices({
      store: this.store,
      oidcManager,
      router: this.router,
      apis: this.apis,
      queries: this.queries,
      createSubscription: this.countryConfig.subscription?.services.createSubscription,
    });

    this.serviceBus = new ServiceBus(this.services, this.resultNotifier, this.router);
  }

  async start() {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
      <React.StrictMode>
        <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
          <Box sx={{ width: "100vw", height: "100vh" }}>
            <Loading title={"Authentification en cours..."} />
          </Box>
        </I18nProvider>
      </React.StrictMode>,
    );

    try {
      const [daphne] = await Promise.all([initDaphne({ baseUrl: this.config.nuvaUrl }), this.services.signIn.call()]);
      this.daphne = daphne;
    } catch {
      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>,
      );
      return;
    }

    const authState = this.store.getState().authState;

    if (authState.type != "signed-in") {
      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>,
      );
      return;
    }

    root.unmount();

    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <DependenciesProvider
          dependencies={{
            store: this.store,
            arianeApi: this.apis.arianeApi,
            queries: this.queries,
            serviceBus: this.serviceBus,
            sadApi: this.apis.sadApi,
            daphne: this.daphne,
            apis: this.apis,
            config: this.config,
            countryConfig: this.countryConfig,
          }}
        >
          <ThemeProvider theme={theme}>
            <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
              <RouterProvider router={this.router} />
            </I18nProvider>
          </ThemeProvider>
        </DependenciesProvider>
      </React.StrictMode>,
    );
  }
}

const OIDC_CLIENT = "kairos-pro-front";

function initOidcManager(oidcUrl: string) {
  const oidcManager = new UserManager({
    client_id: OIDC_CLIENT,
    authority: oidcUrl,
    redirect_uri: window.location.href,
    loadUserInfo: true,
  });
  oidcManager.events.addAccessTokenExpired((error: unknown) => {
    console.debug("Access token expired error", error);
  });
  oidcManager.events.addUserSignedOut(() => {
    alert("User signed out");
  });
  oidcManager.events.addSilentRenewError((error: unknown) => {
    console.debug("Silent renew error", error);
    // reload window to force user to sign in again
    window.location.reload();
  });
  return oidcManager;
}
