import { Container, CssBaseline, Box, Typography } from "@mui/material";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { useApis, useQueries } from "../../providers/Dependencies";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Team } from "@syadem/kairos-team-js";

export function AcceptInvitation(): JSX.Element {
  const { t } = useI18n();
  const apis = useApis();
  const [team, setTeam] = useState<Team | undefined>(undefined);
  const [didFail, setDidFail] = useState(false);
  const { invitationId } = useParams() as { invitationId: string };
  const { allOrganizationsQuery } = useQueries();

  useEffect(() => {
    const acceptInvitation = async () => {
      if (invitationId === undefined || team != undefined) {
        return;
      }
      try {
        // TODO: Wrap this inside a service
        const { team } = await apis.team.teamApi.acceptInvitation(invitationId);
        setTeam(team);
        // refetch the teams
        allOrganizationsQuery.call();
      } catch (_e) {
        setDidFail(true);
      }
    };
    acceptInvitation();
  }, [apis, invitationId, team, allOrganizationsQuery]);

  return (
    <PageLayout title={t("team.acceptInvitation.title")}>
      <Container sx={{ marginTop: "5vh" }}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {didFail ? (
            <Typography gutterBottom>{t("team.acceptInvitation.error")}</Typography>
          ) : team ? (
            <Typography variant="h6" gutterBottom>
              {t("team.acceptInvitation.success", { teamName: team.name })}
            </Typography>
          ) : (
            <Typography variant="h6" gutterBottom>
              {t("common.loading")}
            </Typography>
          )}
        </Box>
      </Container>
    </PageLayout>
  );
}
